.container {
  contain: content;
  position: relative;
}

.imageContainer {
  padding-top: 56.25%;
  aspect-ratio: 16 / 9;

  @media bp(lg) {
    height: 100% !important;
    padding-top: 0;
    aspect-ratio: auto;
  }
}

.content {
  background-image: linear-gradient(to bottom, clr(cb/30) 30%, clr(cb));
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: rem(25);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-radius: rem(2);
}

.title {
  font-size: rem(20);
  line-height: rem(28);
  color: clr(cw);
  font-weight: bold;
  margin-top: rem(5);
}

.postedBy {
  margin-top: rem(5);
  color: clr(cw);
}
