.title {
  font-size: rem(24);
  line-height: rem(28);
  font-weight: bold;

  @mixin transition color;

  @media bp(lg) {
    line-height: rem(32);
  }
}

.excerpt {
  display: none;

  @mixin transition color;

  @media bp(lg) {
    display: block;
    font-size: rem(18);
    line-height: rem(28);
    margin-top: rem(5);
  }
}

.container {
  @media bp(lg) {
    display: grid;
    grid-template-columns: minmax(0, 3fr) minmax(0, 2fr);
    gap: rem(30);
    width: auto;
  }

  &:hover {
    .title {
      color: clr(p-70);
    }

    .excerpt {
      color: clr(p-70);
    }
  }
}

.imageContainer {
  position: relative;
}

.image {
  margin-bottom: rem(20);

  @media bp(lg) {
    margin-bottom: 0;
  }
}

.postedBy {
  margin-top: rem(5);
}

.postedByDate {
  color: clr(g-50);
}
