.list {
  display: flex;
  flex-direction: column;
}

.hero {
  margin-bottom: rem(15);

  @media bp(lg) {
    margin-bottom: rem(30);
  }
}
