.container {
  display: grid;
  gap: rem(15);

  @media bp(lg) {
    gap: rem(30);
    grid-template-columns: repeq(6);
  }
}

.cardLeft {
  @media bp(lg) {
    grid-column: 1 / span 4;
  }
}

.cardRight {
  @media bp(lg) {
    grid-column: 5 / span 2;
  }
}

.rowLeft {
  @media bp(lg) {
    grid-column: 1 / span 3;
  }
}

.rowRight {
  @media bp(lg) {
    grid-column: 4 / span 3;
  }
}
