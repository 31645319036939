.preTitle {
  margin: rem(30) 0 rem(5) 0;
  font-size: rem(20);
  line-height: rem(24);
  font-weight: 500;

  @media bp(lg) {
    margin-top: rem(40);
  }
}

.content {
  margin-top: rem(30);

  @media bp(lg) {
    margin-bottom: rem(40);
  }
}
